class Config {
	constructor(domain) {
		this.uiInfo = {
			isLessThanMD: window.matchMedia('(max-width: 767.98px)').matches,
			dashboardSpacer: 72,
			dashboardSpacerMD: 56,
		};
		this.domain = domain;
		this.tablePageSize = 10;
		this.dashboardSpacer = 72;
		this.dashboardSpacerMD = 56;
		this.reauthGap = 5 * 60 * 1000;
		this.idleTimeout = 60 * 1000;
		this.baseURL = 'https://mmp.world';
		this.baseTitle = 'MMP';
		this.bemsSocketServer = 'https://bems.services:3002';
	}

	setServers(mode) {
		this.mode = mode;
		if (mode === 'dev') {
			this.server = 'http://127.0.0.1:3009/api';
			this.socketServer = 'http://127.0.0.1:3002';
		}
		if (mode === 'prod') {
			this.server = 'https://mmp.monster/api';
			this.socketServer = 'https://mmp.monster:3002';
		}
	}
}

// const config = new Config('app');
const config = new Config('backOffice');

config.setServers('prod');

export default config;
