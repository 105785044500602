import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useTickets() {
	const [tickets, setTickets] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/tickets', '/ticketsContent'];

		async function getTickets() {
			try {
				if (!http.getCurrentUser() || tickets || !allowed.includes(pathname)) return;
				const { data } = await http.getTickets();
				if (isMounted()) setTickets(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getTickets();
	}, [isMounted, tickets, pathname]);
	return [tickets, setTickets];
}
