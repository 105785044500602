import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useTickets from '../../../hooks/useTickets';
import http from '../../../services/httpService';
import Preloader from '../../common/preloader';
import Spinner from '../../common/spinner';

function TicketsContent(props) {
	useDocumentTitle(['TicketsContent']);
	const [isLoading, setIsLoading] = useState(false);
	const [tickets, setTickets] = useTickets();

	if (!tickets) return <Preloader />;
	if (!props.location.state) window.location = '/';

	const { email, uid, ticket, wallet, _id } = props.location.state;
	const sortedTickets = tickets.sort((a, b) => (a.ticket.date > b.ticket.date ? 1 : -1));

	const handleCloseTicket = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.closeTicket({ _id });
			toast.success(data.message);
			setTickets(data.result);
			setIsLoading(false);
			props.history.replace(`/tickets`);
		} catch (ex) {
			setIsLoading(false);
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		}
	};

	const handlePerviousTicket = async () => {
		const index = sortedTickets.findIndex((t) => t._id === _id);
		const target = index === 0 ? sortedTickets.length - 1 : index - 1;
		props.history.push({
			pathname: '/ticketsContent',
			state: sortedTickets[target],
		});
	};

	const handleNextTicket = async () => {
		const index = sortedTickets.findIndex((t) => t._id === _id);
		const target = index === sortedTickets.length - 1 ? 0 : index + 1;
		props.history.push({
			pathname: '/ticketsContent',
			state: sortedTickets[target],
		});
	};

	return (
		<div className='container'>
			<div className='row my-3'>
				<div className='col-12 text-center'>
					<h2>TicketsContent</h2>
					<hr />
				</div>
			</div>

			<div className='row my-5'>
				<div className='col-md-8 offset-md-2 col-12'>
					<p>
						<b>Email:</b> {email}
					</p>
					<p>
						<b>UID:</b> {uid}
					</p>
					<p>
						<b>Temporary MMP wallet:</b> {wallet}
					</p>
					<p>
						<b>Date:</b> {new Date(ticket.date).toLocaleString()}
					</p>
					<p>
						<b>Department:</b> <q>{ticket.department}</q>
					</p>
					<p>
						<b>Priority:</b> <q>{ticket.priority}</q>
					</p>
					<p>
						<b>Title:</b> <q>{ticket.title}</q>
					</p>
					<p>
						<b>Content:</b> <q className='text-break'>{ticket.content}</q>
					</p>
				</div>
			</div>

			<div className='row my-5'>
				<div className='col-12 text-center'>
					<button
						onClick={handlePerviousTicket}
						className='btn btn-sm btn-primary'>
						{isLoading ? <Spinner content='loading' /> : '<< Pervious'}
					</button>
					<button
						onClick={handleCloseTicket}
						className='btn btn-sm btn-danger mx-lg-5 mx-3'>
						{isLoading ? <Spinner content='loading' /> : 'Close Ticket'}
					</button>
					<button
						onClick={handleNextTicket}
						className='btn btn-sm btn-primary px-4'>
						{isLoading ? <Spinner content='loading' /> : 'Next >>'}
					</button>
				</div>
			</div>
		</div>
	);
}

export default TicketsContent;
